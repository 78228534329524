import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { UserStatus } from '../../../typings/organization/org-manage';
let inService = class inService extends Vue {
    constructor() {
        super(...arguments);
        this.UserStatus = UserStatus;
    }
};
inService = __decorate([
    Component({
        name: 'inService',
        components: {
            StaffManage: () => import('@/components/organization/staff-manage/staff-manage.vue'),
        },
    })
], inService);
export default inService;
